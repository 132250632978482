import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/meniul-principal",
        name: "dashboard",
        meta: {
            name: "Meniul Principal"
        },
        component: () =>
            import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue")
    },
    {
        path: "/consiliul-local",
        name: "local-council",
        meta: {
            name: "Consiliul Local"
        },
        component: () =>
            import(
                /* webpackChunkName: "local-council" */ "../views/LocalCouncil.vue"
            )
    },
    {
        path: "/primarul",
        name: "mayor",
        meta: {
            name: "Primarul"
        },
        component: () =>
            import(/* webpackChunkName: "mayor" */ "../views/Mayor.vue")
    },
    {
        path: "/organigrama",
        name: "town-hall",
        meta: {
            name: "Organigrama"
        },
        component: () =>
            import(/* webpackChunkName: "town-hall" */ "../views/TownHall.vue")
    },
    {
        path: "/utilizatori",
        name: "users",
        meta: {
            name: "Utilizatori",
            auth: true
        },
        component: () =>
            import(/* webpackChunkName: "users" */ "../views/Users.vue")
    },
    {
        path: "/legislatie",
        name: "legislation",
        meta: {
            name: "Legislație"
        },
        component: () =>
            import(
                /* webpackChunkName: "legislation" */ "../views/Legislation.vue"
            )
    },
    {
        path: "/registru",
        name: "registry",
        meta: {
            name: ""
        },
        component: () =>
            import(/* webpackChunkName: "registry" */ "../views/Registry.vue")
    },
    {
        path: "/adauga-consilier",
        name: "add-counselor",
        meta: {
            name: "Adaugă Consilier Local",
            auth: true
        },
        component: () =>
            import(
                /* webpackChunkName: "add-counselor" */ "../views/AddCounselor.vue"
            )
    },
    {
        path: "/editeaza-consilier",
        name: "edit-counselor",
        meta: {
            name: "Editează Consilier Local",
            auth: true
        },
        component: () =>
            import(
                /* webpackChunkName: "edit-counselor" */ "../views/AddCounselor.vue"
            )
    },
    {
        path: "/adauga-legislatie",
        name: "add-legislation",
        meta: {
            name: "Adaugă Legislație",
            auth: true
        },
        component: () =>
            import(
                /* webpackChunkName: "add-legislation" */ "../views/AddLegislation.vue"
            )
    },
    {
        path: "/editeaza-legislatie",
        name: "edit-legislation",
        meta: {
            name: "Editează Legislație",
            auth: true
        },
        component: () =>
            import(
                /* webpackChunkName: "edit-legislation" */ "../views/AddLegislation.vue"
            )
    },
    {
        path: "/editeaza-primarul",
        name: "edit-mayor",
        meta: {
            name: "Editează Primarul",
            auth: true
        },
        component: () =>
            import(
                /* webpackChunkName: "edit-mayor" */ "../views/EditMayor.vue"
            )
    },
    {
        path: "/adauga-functionar",
        name: "add-employee",
        meta: {
            name: "Adaugă Funcționar",
            auth: true
        },
        component: () =>
            import(
                /* webpackChunkName: "add-employee" */ "../views/AddEmployee.vue"
            )
    },
    {
        path: "/editeaza-functionar",
        name: "edit-employee",
        meta: {
            name: "Editează Funcționar",
            auth: true
        },
        component: () =>
            import(
                /* webpackChunkName: "edit-employee" */ "../views/AddEmployee.vue"
            )
    },
    {
        path: "/adauga-intrare-in-registru",
        name: "add-record",
        meta: {
            name: "Adaugă Intrare în Registrul",
            auth: true
        },
        component: () =>
            import(
                /* webpackChunkName: "add-record" */ "../views/AddRecord.vue"
            )
    },
    {
        path: "/editeaza-intrare-in-registru",
        name: "edit-record",
        meta: {
            name: "Editează Intrare în Registrul",
            auth: true
        },
        component: () =>
            import(
                /* webpackChunkName: "edit-record" */ "../views/AddRecord.vue"
            )
    },
    {
        path: "/detalii-intrare",
        name: "view-record",
        meta: {
            name: "Detalii"
        },
        component: () =>
            import(
                /* webpackChunkName: "view-record" */ "../views/ViewRecord.vue"
            )
    },
    {
        path: "/login",
        name: "auth",
        meta: {
            name: "Autentificare",
            auth: false
        },
        component: () =>
            import(/* webpackChunkName: "auth" */ "../views/Auth.vue")
    },
    { path: "/", redirect: { name: "dashboard" } }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

export default router;
